.button_outline {
    color: #f1061c !important;
    background-color: #ffffff !important;
    border-color: #f1061c !important;
    border-radius: 0.25rem !important;
    font-size: 12px;
    text-align: center;
}

.buttongrey {
    background-color: #bfbcb2 !important;
    border-color: #bfbcb2 !important;
    border-radius: 0.25rem !important;
    font-size: 12px;
    text-align: center;
}

.buttonred {
    background-color: #f1061c !important;
    border-color: #f1061c !important;
    border-radius: 0.25rem !important;
    font-size: 12px;
    text-align: center;
}

.btn-file {
    position: relative;
    overflow: hidden;
    font-size: 12px;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    font-size: 100px;
    text-align: right;
    opacity: 0;
    outline: none;
    cursor: pointer;
    display: block;
}