.background-imange{
	width: 378px;
    height: 672px;
    position: relative; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.storyback {
    position: absolute;
	width: 378px;
    height: 672px;
}