.react-datepicker__input-container input{
    width: 100%;
}
.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}
.pac-container {
    z-index: 999999999999!important;
}

.richtext-view {
    white-space: pre-wrap;
    p {
      margin: 0;
    }
  }




  .ck.ck-balloon-panel:not(.ck-toolbar-container) {
	z-index: 9999;
}