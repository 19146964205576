.row-active {
    background-color: #ebdfdf;
}
.disabled- {
    &div{
        &::before {
           content: " ";
           position: absolute;
           height: 100%;
           width: 100%;
           background : #ccc;
           opacity: 0.4;
           z-index: 999;
           left: 0;
           top: 0;
        }
    }
}

.visible-on-hover- {
    &item{
        transition: 0.5s ease-in-out;
        opacity: 0;
        height: 0;
    }
    &container {
        &:hover {
            & .visible-on-hover-item {
                opacity: 1;
                height: unset;
            }
        }
    }
}


.limit-text-{
    &1{
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
    }
}

.limit-line-3 {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }



.switch-toggle {
    font-size: 8px;
    border-radius: 8px;
    overflow: hidden;
    float: left;
    text-align: center;
    background: #aaa;
 }
 .switch-toggle div {
     display: inline-block;
     padding: 2px 5px 2px 5px;
     font-weight: bold;
     text-shadow: 0.5px 0.5px 2px #000;
     border: 0.5px solid #888;
     color: white;
     cursor: pointer;
 }
 .switch-toggle .active {
     background-color: red;
     pointer-events: none;
     transition: 1s ease-in-out;
 }



 .clickable {
    cursor: pointer;
 }

 .react-datepicker__close-icon {
    right: -24px;
 }